// import Hls from 'hls.js';

export default ({ url: streamUrl }) => ({
    initialised: false,
    playerInstance: null,
    hlsInstance: null,

    playerReady: false,
    isLoading: true,
    playbackStarted: false,
    isPlaying: false,

    id: null,
    streamUrl,

    hasAudio: true,
    isIos: false,

    init() {
        this.id = this.$id("player");

        this.$watch("$store.muted", () => {
            this.setPlayerMute();
        });
    },

    async initPlayer() {
        if (this.initialised) {
            return;
        }

        this.initialised = true;

        const { default: Hls } = await import("hls.js");

        this.playerInstance = document.createElement("video");

        this.playerInstance.setAttribute("loop", true);
        this.playerInstance.setAttribute("muted", true);
        // this.playerInstance.setAttribute('controls', true);
        // this.playerInstance.setAttribute('controlslist', 'nodownload noremoteplayback nofullscreen novolume noplaybackrate');
        this.playerInstance.setAttribute("playsinline", true);
        // this.playerInstance.setAttribute('disable-picture-in-picture', true);
        this.playerInstance.setAttribute(
            "class",
            "w-full h-full object-contain",
        );

        if (this.playerInstance.canPlayType("application/vnd.apple.mpegurl")) {
            this.playerInstance.src = this.streamUrl;
        } else if (Hls.isSupported()) {
            const hls = new Hls({
                startLevel: -1, // auto
            });
            hls.loadSource(this.streamUrl);
            hls.attachMedia(this.playerInstance);
            this.hlsInstance = hls;
        }

        this.playerInstance.addEventListener(
            "loadedmetadata",
            () => {
                this.playerReady = true;
            },
            { once: true },
        );

        this.playerInstance.addEventListener("playing", () => {
            this.playbackStarted = true;
            this.isPlaying = true;
            this.isLoading = false;
            this.$dispatch("video-played", this.id);
        });

        this.playerInstance.addEventListener("pause", () => {
            this.isPlaying = false;
        });

        this.playerInstance.addEventListener("waiting", () => {
            this.isLoading = true;
        });

        this.playerInstance.addEventListener("error", (event) => {
            this.catchPlayError(event.error);
        });

        this.$refs.wrapper.appendChild(this.playerInstance);

        // this.checkForIos();
    },

    destroy() {
        if (this.hlsInstance) {
            this.hlsInstance.destroy();
        }
    },

    // checkForIos() {
    //     if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
    //
    //     this.isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
    // },

    toggleMute() {
        this.$store.muted = !this.$store.muted;
    },

    setPlayerMute() {
        if (this.playerInstance) {
            this.playerInstance.muted = this.$store.muted;
            this.playerInstance.volume = this.$store.muted ? 0 : 1;
        }
    },

    async play() {
        if (!this.playerInstance || !this.playerReady) {
            return;
        }

        if (this.playerInstance.paused) {
            this.setPlayerMute();

            this.playerInstance.play().catch((error) => {
                this.catchPlayError(error);
            });
        }
    },

    pause() {
        if (this.playerInstance) {
            this.playerInstance.pause();
        }
    },

    toggle() {
        if (this.playerInstance) {
            this.playerInstance.paused ? this.play() : this.pause();
        }
    },

    handleOtherPlayed(event) {
        if (event.detail !== this.id) {
            this.pause();
        }
    },

    catchPlayError(error) {
        if (
            typeof error !== undefined &&
            typeof error?.name !== undefined &&
            error?.name === "NotAllowedError"
        ) {
            this.$store.muted = true;
            this.setPlayerMute();
            this.toggle();
        }
    },
});
